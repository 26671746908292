// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page__container {
  max-width: 100%;
  margin: 1rem auto;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) {
  .neural-network-explanation {
    max-width: 1000px;
    margin: 2rem auto;
    padding: 2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/page-container/PageContainer.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,2CAA2C;EAC3C,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE;IACE,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;EACf;AACF","sourcesContent":[".page__container {\n  max-width: 100%;\n  margin: 1rem auto;\n  padding: 1rem;\n  background-color: rgba(255, 255, 255, 0.95);\n  border-radius: 8px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);\n}\n\n@media (min-width: 768px) {\n  .neural-network-explanation {\n    max-width: 1000px;\n    margin: 2rem auto;\n    padding: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
