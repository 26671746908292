// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}

.scroll-content {
  position: relative;
  z-index: 1;
  min-height: 100%;
}

.content {
  padding: 20px;
  position: relative;
  z-index: 2;
}

.App-header {
  padding: 20px;
  color: #ffffff;
  margin-bottom: 20px;
  border-radius: 8px;
}

main {
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  margin: 0 auto;
}

h1 {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,cAAc;EACd,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,SAAS;AACX","sourcesContent":[".App {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  overflow-y: auto;\n}\n\n.scroll-content {\n  position: relative;\n  z-index: 1;\n  min-height: 100%;\n}\n\n.content {\n  padding: 20px;\n  position: relative;\n  z-index: 2;\n}\n\n.App-header {\n  padding: 20px;\n  color: #ffffff;\n  margin-bottom: 20px;\n  border-radius: 8px;\n}\n\nmain {\n  padding: 20px;\n  border-radius: 8px;\n  max-width: 800px;\n  margin: 0 auto;\n}\n\nh1 {\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
