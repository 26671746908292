import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaStar, FaBook } from "react-icons/fa";
import "./BookManagement.css";
import PageContainer from "components/page-container/pageContainer";

const BookManagement = () => {
  const bookStatuses = [
    { value: "to-buy", label: "À acheter" },
    { value: "to-read", label: "À lire" },
    { value: "reading", label: "En cours de lecture" },
    { value: "read", label: "Lu" }
  ];

  const [books, setBooks] = useState([]);
  const [newBook, setNewBook] = useState({
    title: "",
    author: "",
    status: bookStatuses[0].value,
    rating: 0,
  });

  const addBook = (e) => {
    e.preventDefault();
    setBooks([...books, { ...newBook, id: Date.now() }]);
    setNewBook({ title: "", author: "", status: bookStatuses[0].value, rating: 0 });
  };

  const updateBook = (id, updatedBook) => {
    setBooks(
      books.map((book) => (book.id === id ? { ...book, ...updatedBook } : book))
    );
  };

  const deleteBook = (id) => {
    setBooks(books.filter((book) => book.id !== id));
  };

  const StarRating = ({ rating, onRatingChange }) => {
    const [hover, setHover] = useState(null);

    return (
      <div className="star-rating">
        {[...Array(5)].map((star, index) => {
          const ratingValue = index + 1;
          return (
            <label key={index}>
              <input
                type="radio"
                name="rating"
                value={ratingValue}
                onClick={() => onRatingChange(ratingValue)}
              />
              <FaStar
                className="star"
                size={25}
                color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
                onMouseEnter={() => setHover(ratingValue)}
                onMouseLeave={() => setHover(null)}
              />
            </label>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <PageContainer>
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Gestion de ma Bibliothèque
        </motion.h1>

        <motion.form
          className="book-form"
          onSubmit={addBook}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <input
            type="text"
            placeholder="Titre du livre"
            value={newBook.title}
            onChange={(e) => setNewBook({ ...newBook, title: e.target.value })}
            required
          />
          <input
            type="text"
            placeholder="Auteur"
            value={newBook.author}
            onChange={(e) => setNewBook({ ...newBook, author: e.target.value })}
            required
          />
          <select
            value={newBook.status}
            onChange={(e) => setNewBook({ ...newBook, status: e.target.value })}
          >
            {bookStatuses.map((status) => (
              <option key={status.value} value={status.value}>
                {status.label}
              </option>
            ))}
          </select>
          <button type="submit">Ajouter un livre</button>
        </motion.form>
      </PageContainer>
      <PageContainer>
        {books.length === 0 ? (
          <motion.div 
            className="empty-list"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <FaBook size={50} />
            <p>Votre bibliothèque est vide. Ajoutez des livres pour commencer !</p>
          </motion.div>
        ) : (
          <motion.ul
            className="book-list"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            {books.map((book) => (
              <motion.li
                key={book.id}
                className="book-item"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 50 }}
                transition={{ duration: 0.3 }}
              >
                <div className="book-info">
                  <h3>{book.title}</h3>
                  <p>par {book.author}</p>
                </div>
                <div className="book-actions">
                  <select
                    value={book.status}
                    onChange={(e) =>
                      updateBook(book.id, { status: e.target.value })
                    }
                  >
                    {bookStatuses.map((status) => (
                      <option key={status.value} value={status.value}>
                        {status.label}
                      </option>
                    ))}
                  </select>
                  {book.status === "read" && (
                    <StarRating
                      rating={book.rating}
                      onRatingChange={(rating) => updateBook(book.id, { rating })}
                    />
                  )}
                  <button onClick={() => deleteBook(book.id)}>Supprimer</button>
                </div>
              </motion.li>
            ))}
          </motion.ul>
        )}
      </PageContainer>
    </>
  );
};

export default BookManagement;